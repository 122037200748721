import React, { Component, Suspense } from "react";
import Section from "./section.cmpt";
import Preloader from "./preloader.cmpt";
//Clientes
import banamex  from './clients/banamex.png';
import homework from './clients/homework.png';
import ia from './clients/ia.png';
import mentalia from './clients/mentalia.png';

const URL_SERVICE = "https://us-central1-innatodevelopers-57b3a.cloudfunctions.net/sendMessage";
const $ = window.$;
const M = window.M;




const ImgPreload = React.lazy(() => {
  return new Promise(resolve => setTimeout(resolve, 1000)).then(() =>
    import("./imgPreload.cmpt")
  );
});

class Main extends Component {
  state = {
    correo: "",
    telefono: "",
    nombre: "",
    apellido: "",
    comentario: "",
    error: null,
    success: false
  };

  onChangeValues = event => {
    let { id, value } = event.target;
    let re = "";
    if (id === "telefono") {
      re = /^[0-9]*$/;
      if (re.test(value)) {
        this.setState({ [id]: value, error: null, success: false });
      }
    }
    else {
      this.setState({ [id]: value, error: null, success: false });
    }

  };

  componentDidMount() {

    $(document).ready(() => {
      $("#comentario").characterCounter();
      $(".owl-carousel").owlCarousel({
        dots: true,
        loop: true,
        nav: false,
        autoplay: true,
        autoplayTimeout: 1000,
        items: 6,
        margin: 30,
        responsive: {
          0: {
            items: 2
          },
          480: {
            items: 4
          },
          992: {
            items: 6
          }
        }
      });
    });
  }

  submitHandler = event => {
    event.preventDefault();
    let valido = true;
    let error = "";

    if (this.state.nombre.trim() === "") {
      valido = false;
      error = "Capture su nombre por favor."
    } else if (!this.validateEmail(this.state.correo)) {
      valido = false;
      error = "Ingrese un correo electrónico válido.";
    } else if (!this.validatePhone(this.state.telefono)) {
      valido = false;
      error = "Ingrese un teléfono válido."
    } else if (this.state.comentario.trim() === "") {
      valido = false;
      error = "Capture algún comentario es muy importante para ofrecerte lo mejor.";
    }

    if (valido) {

      let data = {
        nombre: `${this.state.nombre} ${this.state.apellido}`,
        telefono: this.state.telefono,
        correo: this.state.correo,
        comentario: this.state.comentario
      }

      $.ajax({
        url: URL_SERVICE,
        method: "POST",
        data: { data: data }
      }).done((response) => {
        this.setState({ success: response.msj })
      }).fail((error) => {
        let { responseJSON } = error;
        this.setState({ error: responseJSON.error });
      });


    } else {
      this.setState({ error });
    }

  };

  render() {
    return (
      <React.Fragment>
        <main>
          <Section id="about">
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <img
                  className="responsive-img z-depth-2"
                  src="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Fdevelopers.webp?alt=media&token=8c8ad602-3c59-4e14-a7fe-96d515c40fce"
                  alt="Innato Developers"
                />
              </div>
              <div className="col s12 m6 l6 xl6">
                <div className="valign-wrapper">
                  <div className="center-align cabeceraDesc">
                    <h3>¿Quiénes Somos?</h3>
                    <h6>
                      Somos un equipo de especialistas en TI, con más de 6
                      años de experiencia en sector bancario y de seguros,
                      diseñando, desarrollando y asegurando la calidad de soluciones web empresariales
                  </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <div className="valign-wrapper">
                  <div className="center-align cabeceraDesc">
                    <h3>¿Qué hacemos?</h3>
                    <h6>
                      Nos dedicamos al desarrollo de proyectos web y móviles,
                      integración de sistemas, aseguramiento de la calidad del
                      software y soluciones basadas en la nube.
                  </h6>
                  </div>
                </div>
              </div>
              <div className="col s12 m6 l6 xl6">
                <Suspense fallback={<Preloader />}>
                  <ImgPreload
                    clase="responsive-img z-depth-2"
                    alt="Innato Developers"
                    url="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Fque_hacemos-compressor.webp?alt=media&token=f4f4d825-ce65-4c5a-884d-3efa8a017d23"
                  />
                </Suspense>
              </div>
            </div>
          </Section>

          <Section id="services">
            <div className="row">
              <div className="col s12 center-align">
                <label className="titleSection">Servicios</label>
              </div>
              <div className="col s12 center-align">
                <p>En innato somos expertos en:</p>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="far fa-window-restore icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Software a la Medida</p>
                      <p>
                        Te ayudamos a diseñar una solución que optimice los
                        procesos clave de tu negocio para hacer llegar a tus
                        cliente potenciales la propuesta de valor que estás
                        ofreciendo.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar con un sistema que tengo pensado." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Lo necesito</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="fas fa-mobile-alt icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Aplicaciones Móviles</p>
                      <p>
                        Impulsa el crecimiento de tu empresa poniendo tus
                        productos y/o servicios a la mano de tus clientes.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar con una aplicación movil." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Lo Necesito</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="far fa-window-maximize icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Progressive Web App - PWA</p>
                      <p>
                        ¿Necesitas una App que funcione de la misma manera, tanto
                        en un navegador web cómo en un dispositivo móvil?, las
                        Progresive Web App son la solución.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar con una aplicación web." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Lo Necesito</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="fab fa-cloudversify icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Soluciones Basadas en la nube</p>
                      <p>
                        Beneficiate de las bodades de usar tecnologias basadas en
                        nube, te ayudamos a migrar tu negocio o llevar tu idea a
                        la nube con lo que podrás ahorra en infraestructura.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar con un sistema basado en la nube." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Lo Necesito</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="fas fa-chart-line icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Posicionamiento SEO</p>
                      <p>
                        Posiciona el sitio web de tu empresa para que más personas
                        puedan encontrar tu negocio.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar a posicionar mi sitio web." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Lo Necesito</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col s12 m6 l6 xl6">
                <div className="card horizontal">
                  <div className="card-image center-align">
                    <i className="fas fa-tasks icon" />
                  </div>
                  <div className="card-stacked">
                    <div className="card-content">
                      <p className="titleCard">Testing</p>
                      <p>
                        La calidad de los productos de software es muy importante
                        para ofrecer mejores soluciones a tus clientes.
                    </p>
                      <a href="#contact"
                        onClick={() => {
                          this.setState({ comentario: "Me podrían apoyar a mejorar la calidad de mi aplicación." }, () => {
                            M.updateTextFields();
                          })
                        }
                        }
                      >Necesito Esto</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <Section id="clients">
            <div className="row">
              <div className="col s12 center-align">
                <label className="titleSection">Clientes</label>
              </div>
              <div className="col s12 owl-carousel">
                <div> <img src={banamex} alt="" /></div>
                <div> <img src={mentalia} alt="" /></div>
                <div> <img src={homework} alt="" /></div>
                <div> <img src={ia} alt="" /></div>
              </div>
            </div>

          </Section>
          <Section id="contact">
            <div className="row">
              <div className="col s12 center-align">
                <label className="titleSection">Contactanos</label>
              </div>
              <div className="col s12 center-align">
                <p>
                  Sé nuestro próximo caso de éxito, déjanos tus datos, será
                  un placer poder ser parte del crecimiento de tu negocio.
              </p>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m6 l6 xl6">
                <form className="col s12 " onSubmit={this.submitHandler}>
                  <div className="row">
                    <div className="input-field col s6">
                      <input
                        id="nombre"
                        type="text"
                        value={this.state.nombre}
                        maxLength="30"
                        onChange={this.onChangeValues}
                      />
                      <label htmlFor="nombre" className="active">
                        Nombre*
                    </label>
                    </div>
                    <div className="input-field col s6">
                      <input
                        id="apellido"
                        type="text"
                        value={this.state.apellido}
                        maxLength="50"
                        onChange={this.onChangeValues}
                      />
                      <label htmlFor="apellido">Apellido (s)</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="correo"
                        type="text"
                        value={this.state.correo}
                        maxLength="50"
                        onChange={this.onChangeValues}
                      />
                      <label htmlFor="correo">Correo electrónico*</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <input
                        id="telefono"
                        type="text"
                        maxLength="10"
                        value={this.state.telefono}
                        onChange={this.onChangeValues}
                      />
                      <label htmlFor="telefono">Teléfono*</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input-field col s12">
                      <textarea
                        id="comentario"
                        className="materialize-textarea"
                        value={this.state.comentario}
                        onChange={this.onChangeValues}
                        data-length="200"
                      />
                      <label htmlFor="comentario">Comentarios*</label>
                    </div>
                  </div>
                  {
                    this.state.error ?
                      <div className="row">
                        <div className="col s12 center-align red-text">
                          {this.state.error}
                        </div>
                      </div>
                      : null
                  }
                  {
                    this.state.success ?
                      <div className="row">
                        <div className="col s12 center-align green-text">
                          {this.state.success}
                        </div>
                      </div>
                      : null
                  }


                  <div className="row">
                    <div className="col s6 left-align">
                      <span>Campos obligatorios (*)</span>
                    </div>
                    <div className="col s6 right-align">
                      <button className="waves-effect indigo accent-3 btn">
                        <i className="far fa-paper-plane left" />
                        Enviar
                    </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col s12 m6 l6 xl6">
                <div className="valign-wrapper imageContact">
                  <Suspense fallback={<Preloader />}>
                    <ImgPreload
                      clase="responsive-img z-depth-2"
                      alt="Cliente Satisfecho"
                      url="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Fcontact-compressor.jpg?alt=media&token=89a35529-e6b9-4689-90f3-6043cf453887"
                    />
                  </Suspense>
                </div>
              </div>
            </div>
          </Section>
        </main>
      </React.Fragment>
    );
  }

  validateEmail(email) {
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
  }

  validatePhone(telefono) {
    var re = /^\d{10}$/;
    return re.test(telefono);
  }
}

export default Main;
