
const M = window.M;

export function getCategories(firestore) {
    return new Promise((resolve, reject) => {
        firestore.collection("categories").get().then((result) => {
            let categories = [];
            result.forEach((value, index) => {
                let data = value.data();
                data.id = value.id;
                categories.push(data);
            });
            resolve(categories);
        }).catch((error) => {
            reject({error})
            M.toast({ html: "No se lograron obtener las categorías.", classes: 'red darken-1' });
        });
    });
}

export function saveCategorie(firestore, data) {
    return new Promise((resolve, reject) => {

        if (data.id) {
            let id = data.id;
            delete data.id;
            firestore.collection("categories").doc(id).update(data).then((result) => {
                M.toast({ html: "Se actualizo correctamente la categoría.", classes: 'green darken-1' });
                resolve(true);
            }).catch((error) => {
                console.log(error);
                M.toast({ html: "No se logro guardar la categoría.", classes: 'red darken-1' });
            })
        } else {
            firestore.collection("categories").add(data).then((result) => {
                M.toast({ html: "Se guardo correctamente la categoría.", classes: 'green darken-1' });
                resolve({ id: result.id });
            }).catch((error) => {
                console.log(error);
                M.toast({ html: "No se logro guardar la categoría.", classes: 'red darken-1' });
            });
        }
    });
}

export function deleteCategorie(firestore,id){
    return new Promise((resolve,reject)=>{
        //Revisa que no existe algun post con esta categoria.
        firestore.collection("blog").where("categorie","==",id).get().then((result)=>{
            let posts = [];

            result.forEach((value,index)=>{
                posts.push(value);
            });

            if(posts.length > 0){
                M.toast({ html: "No se puede elimiar la categoría ya que tiene posts asignados.", classes: 'red darken-1' });
            }else{
                firestore.collection("categories").doc(id).delete().then((result)=>{
                    M.toast({ html: "Categoría eliminada.", classes: 'green darken-1' });
                }).catch((error)=>{
                    console.log(error);
                    M.toast({ html: "No se logro elimiar la categoría.", classes: 'red darken-1' });
                })
            }

        });
    });
}

//BLOG

export function getPost(firestore, id) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").doc(id).get().then((result) => {

            if (result.exists) {
                let data = result.data();
                let post = {
                    id: result.id,
                    title: data.title,
                    by: data.by,
                    categorie: data.categorie,
                    data: data.data,
                    activo: data.active,
                    created: data.created,
                    description: data.description ? data.description: "",
                    updated: data.updated ? data.updated: false
                }

                resolve({ post });
            } else {
                M.toast({ html: "Id de post no encontrado.", classes: 'red darken-1' })
            }

        }).catch((error) => {
            M.toast({ html: "No se logro obtener resultados.", classes: 'red darken-1' })
        });
    });

}


export function getPostByTitle(firestore, titleToSearch) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").where("titleToSearch","==",titleToSearch).get().then((result) => {
            if (result.docs.length) {
                let data = result.docs[0].data();
                let post = {
                    id: result.id,
                    title: data.title,
                    by: data.by,
                    categorie: data.categorie,
                    data: data.data,
                    activo: data.active,
                    created: data.created,
                    description: data.description ? data.description: "",
                    updated: data.updated ? data.updated: false
                }

                resolve({ post });
            } else {
                M.toast({ html: "Id de post no encontrado.", classes: 'red darken-1' })
            }

        }).catch((error) => {
            M.toast({ html: "No se logro obtener resultados.", classes: 'red darken-1' })
        });
    });

}


export function deletePost(firestore, id) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").doc(id).delete().then((result) => {
            resolve(true);
        }).catch((error) => {
            M.toast({ html: "No se logro obtener resultados.", classes: 'red darken-1' })
        });
    });

}

export function saveBlog(firestore, data) {
    return new Promise((resolve, reject) => {

        if (data.id) {
            let id = data.id;
            delete data.id;
            data.updated = new Date().getTime();
            firestore.collection("blog").doc(id).update(data).then((result) => {
                M.toast({ html: "Se actualizo correctamente el post.", classes: 'green darken-1' });
                resolve(true);
            }).catch((error) => {
                console.log(error);
                M.toast({ html: "No se logro guardar el post.", classes: 'red darken-1' });
            })
        } else {
            data.created = new Date().getTime();
            firestore.collection("blog").add(data).then((result) => {
                M.toast({ html: "Se guardo correctamente el post.", classes: 'green darken-1' });
                resolve({ id: result.id });
            }).catch((error) => {
                console.log(error);
                M.toast({ html: "No se logro guardar el post.", classes: 'red darken-1' });
            });
        }
    });
}

export function activeBlog(firestore, data) {
    return new Promise((resolve, reject) => {

        firestore.collection("blog").doc(data.id).update({active:data.active}).then((result) => {
            M.toast({ html: "Se actualizo correctamente el post.", classes: 'green darken-1' });
            resolve(true);
        }).catch((error) => {
            console.log(error);
            M.toast({ html: "No se logro guardar el post.", classes: 'red darken-1' });
        })

    });
}

export function getBlog(firestore) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").get().then((result) => {
            let post = [];
            result.forEach((value, index) => {
                let data = value.data();
                data.id = value.id;
                post.push(data);
            });
            resolve(post);
        }).catch((error) => {
            M.toast({ html: "No se lograron obtener los post.", classes: 'red darken-1' });
        });
    });
}

export function getActiveBlog(firestore) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").orderBy("created","desc").get().then((result) => {
            let post = [];
            result.forEach((value, index) => {
                let data = value.data();
                data.id = value.id;
                if(data.active){
                    post.push(data);
                }
            });
            resolve(post);
        }).catch((error) => {
            console.log(error);
            reject({error})
            M.toast({ html: "No se lograron obtener los post.", classes: 'red darken-1' });
        });
    });
}

// export function getActiveBlogLastFive(firestore) {
//     return new Promise((resolve, reject) => {
//         firestore.collection("blog").orderBy("created","desc").limit(5).get().then((result) => {
//             let post = [];
//             result.forEach((value, index) => {
//                 let data = value.data();
//                 data.id = value.id;
//                 if(data.active){
//                     post.push(data);
//                 }
//             });
//             resolve(post);
//         }).catch((error) => {
//             console.log(error);
//             M.toast({ html: "No se lograron obtener los post.", classes: 'red darken-1' });
//         });
//     });
// }

export function getActiveBlogLastFive(firestore) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").orderBy("created","desc").limit(5).get().then((result) => {
            let post = [];
            result.forEach((value, index) => {
                let data = value.data();
                data.id = value.id;
                if(data.active){
                    post.push(value);
                }
            });
            resolve({post});
        }).catch((error) => {
            console.log(error);
            M.toast({ html: "No se lograron obtener los post.", classes: 'red darken-1' });
        });
    });
}


export function getActiveBlogPagination(firestore,lastCreated) {
    return new Promise((resolve, reject) => {
        firestore.collection("blog").orderBy("created","desc").startAfter(lastCreated).limit(5).get().then((result) => {
            let post = [];
            result.forEach((value, index) => {
                let data = value.data();
                data.id = value.id;
                if(data.active){
                    post.push(value);
                }
            });
            resolve({post});
        }).catch((error) => {
            console.log(error);
            M.toast({ html: "No se lograron obtener los post.", classes: 'red darken-1' });
        });
    });
}

export function getCategorieData(categories,categorie){

    let cat = {};

    categories.forEach((value,index)=>{
        if(value.id === categorie){
            cat = value;
        }
    })

    return cat;

}

export function eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(" ", 'g'), "-").replace(/[^a-zA-Z0-9--]/g, '');
}

export function   dateToString(date) {
    let fecha = new Date(date);
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }

    return fecha.toLocaleDateString('mx-ES', options)
}


