import React, { Component } from 'react';
import { getCategories, saveCategorie,eliminarDiacriticos,deleteCategorie } from './blog.code';
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from 'firebase';

const M = window.M;


class Categories extends Component {


    state = {
        categories: [],
        categorie: "",
        firestore: firebase.firestore()
    }


    componentWillMount() {

        var unsub = firebase.firestore().collection('categories').onSnapshot((docSnapshot) => {
            let doc = docSnapshot.docChanges()[0];
            if(doc && (doc.type === "added" || doc.type === "modified")){
                let categories = this.state.categories;
                let data = doc.doc.data();
                data.id = doc.doc.id;
                categories.push(data);
                this.setState({categories},()=>{
                    getCategories(this.state.firestore).then((result) => {
                        this.setState({ categories: result });
                    });
                });
            }else{
                getCategories(this.state.firestore).then((result) => {
                    this.setState({ categories: result });
                });
            }

        });

        this.setState({unsub});

       
    }

    componentWillUnmount(){
        this.state.unsub();
    }

    submitCategorie = (event) => {
        event.preventDefault();

        if (this.state.categorie.trim() === "") {
            M.toast({ html: "Capture el nombre de la categoría.", classes: 'red darken-1' })
        } else {
            let sinDiacriticos = eliminarDiacriticos(this.state.categorie);

            let data = {
                categoria: this.state.categorie,
                vlink: sinDiacriticos.trim().toLowerCase()
            }

            if (this.state.id) {
                data.id = this.state.id;
            }


            saveCategorie(this.state.firestore, data).then((result) => {
         
                this.setState({ id: null,categorie:"" });
                
            });

        }

    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 m8 l8 xl8 ">
                        {
                            this.state.categories.length > 0 ?
                                <ReactTable className="z-depth-4"
                                    data={this.state.categories}
                                    pageSize={6}
                                    columns={[
                                        {
                                            Header: "Categorías",
                                            columns: [
                                                {
                                                    Header: "Categoría",
                                                    accessor: "categoria"
                                                },
                                                {
                                                    Header: "Editar",
                                                    accessor: "id",
                                                    Cell: row => (
                                                        <div className="center-align">
                                                            <div className="waves-effect waves btn-flat"
                                                                onClick={()=>{
                                                                    this.setState({id:row.value,categorie:row.original.categoria},()=>{
                                                                        M.updateTextFields();
                                                                    })
                                                                }}
                                                            >
                                                                <i className="material-icons blue-text">edit</i>
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "Eliminar",
                                                    accessor: "id",
                                                    Cell: row => (
                                                        <div className="center-align">
                                                            <div className="waves-effect waves btn-flat"
                                                                onClick={()=>{
                                                                    deleteCategorie(this.state.firestore,row.value);
                                                                }}
                                                            >
                                                                <i className="material-icons red-text">clear</i>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            ]
                                        }
                                    ]}
                                />
                                : <label>Aún no cuentas con categorías registradas.</label>
                        }
                    </div>
                    <div className="col s12 m4 l4 xl4 ">
                        <div className="row z-depth-4">
                            <div className="col s12 center-align blue lighten-1 ">
                                <h6 className="white-text">Categoría</h6>
                            </div>
                            <form className="col s12" onSubmit={this.submitCategorie}>
                                <div className="row">
                                    <div className="input-field col s12">
                                        <input id="categorie" type="text" value={this.state.categorie}
                                            onChange={(event) => {
                                                let { value, id } = event.target;
                                                this.setState({ [id]: value });
                                            }}
                                        />
                                        <label htmlFor="categorie">Categoría:</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 center-align">
                                        <button type="submit" className="btn waves-effect blue lighten-1"><i className="material-icons left">save</i>Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default Categories;