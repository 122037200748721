import React, { Component, Suspense } from 'react';
import Preloader from './preloader.cmpt';
import firebase from 'firebase';
const ImgSideNav = React.lazy(() => {
    return new Promise(resolve => setTimeout(resolve, 1000)).then(
        () =>
            import("./imgPreload.cmpt")
    );
});


class Header extends Component {


    componentDidMount() {
        let messaging = firebase.messaging();
        messaging.requestPermission().then(() => {
            console.log("tiene permiso ayayay");
            messaging.getToken().then((token) => {
                console.log("token", token);
                firebase.firestore().collection('pushNotifications').add({token});
            }).catch((error) => {
                console.log(error);
            });
        })
            .catch((error) => {
                console.log(error);
            });

    }

    render() {
        return (
            <header id="header">
                <div className="navbar-fixed ">
                    <nav className="transparent" id="navMenu">
                        <div className="nav-wrapper">
                            <a href="/" className="brand-logo">
                                <img
                                    id="imgLogo"
                                    className="responsive-img hide-on-med-and-down"
                                    src="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Flogofull.png?alt=media&token=13be08e8-91bb-47a4-9955-b00668b358a6"
                                    alt="Innato Developers" />
                                <img
                                    id="imgLogoMin"
                                    className="responsive-img hide-on-med-and-up show-on-medium-and-down img-min"
                                    src="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Flogo.png?alt=media&token=734380bb-a6c1-488b-a411-739144239b15"
                                    alt="Innato Developers" />
                            </a>
                            <ul id="nav-desktop" className="right hide-on-med-and-down">
                                <li><a href="/blog" className="sidenav-close">Blog</a></li>
                                <li><a href="/#about">Nosotros</a></li>
                                <li><a href="/#services">Servicios</a></li>
                                <li><a href="/#clients">Clientes</a></li>
                                <li><a href="/#contact">Contacto</a></li>
                            </ul>
                            <ul id="nav-mobile" className="right hide-on-med-and-up show-on-medium-and-down">
                                <li><a href="!#" data-target="slide-out" className="sidenav-trigger"><i className="fas fa-ellipsis-v"></i></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul id="slide-out" className="sidenav">
                    <li className="backBlue"><div className="user-view">
                        <div className="background">
                            <Suspense fallback={<Preloader />}>
                                <ImgSideNav
                                    clase="imgSideNav"
                                    url="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Flogo-f-compressor.png?alt=media&token=9066f181-0179-4359-bf7d-d0e33d74cc02"
                                    alt="Innato Developers" />
                            </Suspense>
                        </div>
                    </div>
                    </li>
                    <li><a href="/blog" className="sidenav-close">Blog</a></li>
                    <li><a href="/#about" className="sidenav-close">Nosotros</a></li>
                    <li><a href="/#services" className="sidenav-close">Servicios</a></li>
                    <li><a href="/#clients" className="sidenav-close">Clientes</a></li>
                    <li><a href="/#contact" className="sidenav-close">Contacto</a></li>
                </ul>
                <div className="container">
                    <div id="inicio" className="row">
                        <div className="col s12 m6 l6 xl6">
                            <img className="responsive-img" src="https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2FIllustration.png?alt=media&token=37187453-274f-4583-b78c-d8126533f131" alt="Desarrollo a la Medida" />
                        </div>
                        <div className="col s12  m6 l6 xl6">
                            <div className="valign-wrapper">
                                <div className="center-align cabeceraDesc white-text">
                                    <h1 className="titleInnato">Innato Developers</h1>
                                    <h6>✅Especialistas en desarrollo de software con soluciones personalizadas.👌 Web 💻Mobiles 📱SEO 📈QA 🙋Gcloud ☁️</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }

}


export default Header;