import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import { getActiveBlog, getCategories,getCategorieData,eliminarDiacriticos } from '../blog/blog.code';


class Footer extends Component {

  state = {
    posts: [],
    categories: [],
    firestore: firebase.firestore()
  }

  componentWillMount() {
    getActiveBlog(this.state.firestore).then((result) => {
      this.setState({ posts: result });
    })
    getCategories(this.state.firestore).then((result) => {
      this.setState({ categories: result })
    })

  }

  render() {
    return (
      <footer className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col l6 s12">
              <h5 className="white-text">Post Recientes</h5>
              <ul>
                {
                  this.state.posts.length > 0 ?
                    this.state.posts.map((value,index)=>{
                      return (
                        <li key={index}>
                          <Link to={{pathname:`/blog/${getCategorieData(this.state.categories, value.categorie).vlink}/${eliminarDiacriticos(value.title).trim().toLowerCase()}`,
                          state:{id:value.id}}} className="white-text capitalize" > {value.title} </Link>
                        </li>
                      )
                    })
                  : null
                }
              </ul>
            </div>
            <div className="col l4 offset-l2 s12">
              <h5 className="white-text">Siguenos en:</h5>
              <ul className="ulFooter">
                <li>
                  <a className="grey-text text-lighten-3"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Innato-Developers-2730691893614099"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a className="grey-text text-lighten-3 ml-1"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=+525576125726&text=Hola necesito apoyo" target="_blank">
                    <i className="fab fa-whatsapp-square"></i>
                  </a>
                  <a className="grey-text text-lighten-3 ml-1"
                    rel="noopener noreferrer"
                    href="https://github.com/InnatoDevelopers" target="_blank">
                    <i className="fab fa-github-square"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container">
            © 2019 Innato Developers
              {/* <a className="grey-text text-lighten-4 right" href="#!">More Links</a> */}
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;