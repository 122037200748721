import React,{Component} from 'react';
import CKEditor from 'ckeditor4-react';

class Editor extends Component{

    state = {
        data:""
    }

    onEditorChange=( evt )=> {
        console.log(evt.editor.getData());
        this.setState( {
            data: evt.editor.getData()
        } ,()=>{
            this.props.setData(this.state.data);
        });
    }

    render(){
        return(
      
                <CKEditor
                    data={this.props.data}
                    onChange={this.onEditorChange}
                />
            
        )
    }
}


export default Editor;