export const FIREBASECONFIG = {
    apiKey: "AIzaSyDh5WQA5OlHmqt5j44etfhs6nH8aW9wHbA",
    authDomain: "innatodevelopers-57b3a.firebaseapp.com",
    databaseURL: "https://innatodevelopers-57b3a.firebaseio.com",
    projectId: "innatodevelopers-57b3a",
    storageBucket: "innatodevelopers-57b3a.appspot.com",
    messagingSenderId: "457634736223",
    appId: "1:457634736223:web:9886b93fa15cbfd4"
}

const ERROR_CODES = {
    "auth/invalid-email":"Introduzca un correo electrónico válido.",
    "auth/user-disabled":"Su usuario ha sido inhabilitado.",
    "auth/user-not-found":"Usuario no encontrado.",
    "auth/wrong-password":"Contraseña no válida.",
}


export function getError(error){
    if (ERROR_CODES[error.code]){
        return ERROR_CODES[error.code]
    }else{
        return error.message
    }
}