import React, { Component } from 'react';
import LoadingCmpt from '../generics/loading.cmpt';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { getCategories, saveBlog, getPost, deletePost,eliminarDiacriticos } from './blog.code';
import Editor from './editor.cmpt';

const M = window.M;

class PostForm extends Component {

    state = {
        categories: [],
        categorie: "",
        title: "",
        description:"",
        by: "",
        data: "",
        activo: false,
        loading: true,
        firestore: firebase.firestore()
    }

    changeValues = (event) => {
        let { id, value } = event.target;

        this.setState({ [id]: value });
    }

    onChangeData = (data) => {
        this.setState({ data: data });
    }

    componentWillMount() {
        if (this.props.match) {
            let { id } = this.props.match.params;
            if (id) {
                getPost(this.state.firestore, id).then((result) => {
                    if (result.post) {
                        let { categorie, title, by, data, activo, id } = result.post;
                        this.setState({ categorie, title, by, data, activo, loading: false, id }, () => {
                            M.updateTextFields()
                        })
                    }
                })
            } else {
                this.setState({ loading: false });
            }
        }

        getCategories(this.state.firestore).then((result) => {
            this.setState({ categories: result }, () => {
                var elems = document.querySelectorAll('select');
                M.FormSelect.init(elems, {});
            });
        });
    }


    submitForm = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        let valido = true;

        if (this.state.title.trim() === "") {
            valido = false;
            M.toast({ html: "Titúlo Requerido.", classes: 'red darken-1' });
        }

        if (this.state.categorie.trim() === "") {
            valido = false;
            M.toast({ html: "Categoría Requerida.", classes: 'red darken-1' });
        }

        if (this.state.by.trim() === "") {
            valido = false;
            M.toast({ html: "Creador Requerido.", classes: 'red darken-1' });
        }

        if (this.state.data.trim() === "") {
            valido = false;
            M.toast({ html: "Contenido Requerido.", classes: 'red darken-1' });
        }

        if (valido) {
            let data = {
                data: this.state.data,
                title: this.state.title,
                categorie: this.state.categorie,
                by: this.state.by,
                active: this.state.activo,
                description:this.state.description,
                titleToSearch:  eliminarDiacriticos(this.state.title).toUpperCase()
            }

            if (this.state.id) {
                data.id = this.state.id
            }

            saveBlog(this.state.firestore, data).then((result) => {
                if (result.id) {
                    this.setState({ id: result.id, loading: false }, () => {
                        M.updateTextFields();
                        var elems = document.querySelectorAll('select');
                        M.FormSelect.init(elems, {});
                    });
                } else {
                    this.setState({ loading: false }, () => {
                        M.updateTextFields();
                        var elems = document.querySelectorAll('select');
                        M.FormSelect.init(elems, {});
                    });
                }
            })

        } else {
            this.setState({ loading: false }, () => {
                M.updateTextFields();
            })
        }

    }

    deletePost = () => {
        this.setState({ loading: true }, () => {
            deletePost(this.state.firestore, this.state.id).then((result) => {
                if (result) {
                    this.setState({ id: null, title: "", categorie: "", data: "", by: "", activo: false, loading: false });
                }
            });
        });

    }

    render() {
        if (this.state.loading) {
            return <LoadingCmpt />
        }
        return (
            <section id="userForm" className="container">
                <div className="row">
                    <div className="col s12 left-align">
                        <Link to={"/dashboard"}> <i className="material-icons cursor-pointer left blue-text">arrow_back</i> <i>Dashboard</i></Link>
                    </div>
                </div>
                <div className="row  z-depth-4">
                    <div className="col s12 center-align blue lighten-1">
                        <h6 className="white-text">{this.state.id ? "Editar Post" : "Nuevo Post"}</h6>
                    </div>
                    <form className="col s12 mt-1" onSubmit={this.submitForm}>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix blue-text">clear_all</i>
                                <select id="categorie" value={this.state.categorie} onChange={this.changeValues}>
                                    <option value="" disabled defaultValue>Elige una opción</option>
                                    {this.state.categories.map((value, index) => {
                                        return (<option value={value.id} key={index}>{value.categoria}</option>)
                                    })}
                                </select>
                                <label>Categoría *: </label>
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix blue-text">title</i>
                                <input id="title" type="text" className="validate"
                                    value={this.state.title}
                                    onChange={this.changeValues}
                                />
                                <label htmlFor="title">Título *:</label>
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix blue-text">account_circle</i>
                                <input id="by" type="text" className="validate"
                                    value={this.state.by}
                                    onChange={this.changeValues}
                                />
                                <label htmlFor="by">Creado Por *:</label>
                            </div>
                            <div className="input-field col s12">
                                <i className="material-icons prefix blue-text">description</i>
                                <textarea id="description" className="materialize-textarea"
                                value={this.state.description}
                                onChange={this.changeValues}
                                ></textarea>
                                <label html="description">Breve Descripción</label>
                                <span class="helper-text" data-error="wrong" data-success="right">Esta descripción es para meta tags que google analitycs toma en cuenta.</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <label>Contenido *:</label>
                                <Editor setData={this.onChangeData} data={this.state.data} />
                            </div>
                            <div className="col s12">
                                <label className="red-text">Campos Requeridos (*)</label>
                            </div>
                            <div className="col s12">
                                <p>
                                    <label>
                                        <input type="checkbox" onChange={(event) => {
                                            let checked = event.target.checked;
                                            this.setState({ activo: checked });
                                        }} checked={this.state.activo} />
                                        <span>Activo</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className={`${this.state.id ? "col s6 right-align" : "col s12 center-align"} `}>
                                <button type="submit" className="btn btn-small waves-effect blue lighten-1 text-button"><i className="material-icons right">save</i>Guardar</button>
                            </div>
                            {
                                this.state.id ?
                                    <div className="col s6 left-align">
                                        <button type="button"
                                            onClick={this.deletePost}
                                            className="btn btn-small waves-effect red lighten-1 text-button"><i className="material-icons right">close</i>Eliminar</button>
                                    </div>
                                    : null
                            }
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}

export default PostForm;