import React from 'react';
const Section = (props) => {


    return (
        <section id={props.id}>
            <div className="container">
               {props.children}
            </div>
        </section>
    )

}

export default Section;