import React,{Component} from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import PostShow from '../blog/post-show.cmpt';
import Main from '../landing/Main.cmpt';
import Login from '../admin/login.cmpt';
import Posts from '../blog/post-list-users.cmpt';
import Dashboard from '../admin/dashboard.cmpt';
import Footer from '../landing/Footer.cmpt';

class RouterApp extends Component{

    render(){
        return(
            <BrowserRouter>
                <Switch>
                    <Route path="/blog/:categorie/:title" component={PostShow}  />
                    <Route path="/blog" component={Posts} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/admin" component={Login} />
                    <Route path="/" component={Main} />
                </Switch>
                <Footer />
            </BrowserRouter>
    
        )
    }

}

export default RouterApp;