/*global FB*/
import React, { Component } from 'react';
import { getPost, dateToString, getCategorieData ,getPostByTitle} from './blog.code';
import { Link } from 'react-router-dom';
import firebase from 'firebase'
import LoadingCmpt from '../generics/loading.cmpt';
import './blog.css';
import {
    WhatsappShareButton,
    EmailShareButton,
    TwitterShareButton,
} from 'react-share';

const $ = window.$;
const ga = window.ga;
class PostShow extends Component {

    state = {
        categorie: "",
        title: "",
        by: "",
        data: "",
        description: "",
        urlShare: false,
        created: false,
        updated: false,
        firestore: firebase.firestore(),
        id: null
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.location && nextProps.match) {
            this.getPostToShow(nextProps.match, nextProps.location);
        }
    }

    componentWillMount() {
        debugger;
        if (!this.props.location.state && !this.props.match) {
            this.props.history.push("/blog")
        } else {
            this.getPostToShow(this.props.match, this.props.location)
        }

    }

    getPostToShow(match, location) {

        if (match) {
           
            let { preview, categorie,title } = match.params;
            let categorieDesc = categorie;
            let id  = null;
            debugger;
            if(location  && location.state ){
                id = location.state.id;
            }


            if (id && this.state.id !== id) {
                this.setState({ loading: true }, () => {
                    getPost(this.state.firestore, id).then((result) => {
                        this.makePost(result,preview,categorieDesc,location);
                    });
                });

            }else if(title.trim() !== ""){

                if(title.includes("?fbclid")){
                    let attr = title.split("?fbclid");
                    title = attr[0];
                }

                getPostByTitle(this.state.firestore,title.trim().toUpperCase()).then((result)=>{
                    this.makePost(result,preview,categorieDesc,location);
                }); 
            }else {
                this.setState({ loading: false });
            }

            if (preview === "true") {
                this.setState({ preview: true });
            } else {
                $("#header").addClass("BlogHeader")
            }
        }
    }

    makePost(result,preview,categorieDesc,location){
        if (result.post) {
            let { categorie, title, by, data, id, created, updated, description } = result.post;
            this.setState({ categorie, title, by, data, loading: false, id, created, updated }, () => {
                $(document).ready(() => {
                    document.title = `Innato Developers Blog | ${this.state.title} `;
                    $("#inicio").hide();
                    $("meta[property='og\\:title']").attr("content", `Innato Developers Blog | ${this.state.title} `);
                    $("meta[property='og\\:site_name']").attr("content", `Innato Developers Blog | ${this.state.title} `);
                    $("meta[property='og\\:url']").attr("content", `https://innatodevelopers.com${location.pathname}`);

                    $("meta[name='title']").attr("content", `Innato Developers Blog | ${this.state.title} `);
                    $("meta[name='site_name']").attr("content", `Innato Developers Blog | ${this.state.title} `);
                    $("meta[name='url']").attr("content", `https://innatodevelopers.com${location.pathname}`);


                    if (description) {
                        $("meta[property='og\\:description']").attr("content", description);
                        $("meta[name='description']").attr("content", description);
                        this.setState({ description });
                    }
                    if (preview === "true") {
                        this.setState({ preview: true }, () => {
                            $('html, body').animate({ scrollTop: 0 }, 800);
                        });
                    } else {
                        $("#header").addClass("BlogHeader");
                        this.setState({ urlShare: `https://innatodevelopers.com${location.pathname}` }, () => {
                            //Es usuario se ejecuta acción de analitycs
                            ga('send', {
                                hitType: 'event',
                                eventCategory: `Blog - ${categorieDesc}`,
                                eventAction: `${categorieDesc}`,
                                eventLabel: `${this.state.title}`
                            });
                            $('html, body').animate({ scrollTop: 0 }, 800);
                        });
                    }

                });
            })
        }
    }

    shareFacebook() {
        FB.ui({
            method: 'share',
            href: this.state.urlShare,
            hashtag: "#InnatoDevelopers",
            quote: `Innato Developers Blog | ${this.state.title} `,
            name: `Innato Developers Blog | ${this.state.title} `,
            picture: "https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2FUntitled-1-compressor.jpg?alt=media&token=c57db92d-e24f-4bc0-9055-cc69f38c398d",
            description: this.state.description === "" ? `Innato Developers Blog | ${this.state.title} ` : this.state.description
        })
    }

    componentDidMount() {
        $(document).ready(() => {
            setTimeout(() => {
                $("img").removeAttr("style");
                $('a[href^="http://"]').not('a[href*=gusdecool]').attr('target', '_blank');
                $('a[href^="https://"]').not('a[href*=gusdecool]').attr('target', '_blank');
            }, 1000);

            window.fbAsyncInit = function () {
                FB.init({
                    appId: '458711194888612',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v2.10'

                });
            };

        });

    }

    render() {
        if (this.state.loading) {
            return <LoadingCmpt />
        }

        return (
            <div className="blogContent container">
                {
                    this.state.preview ?
                        <div className="row">
                            <div className="col s12 left-align">
                                <Link to={"/dashboard"}> <i className="material-icons cursor-pointer left blue-text">arrow_back</i> <i>Dashboard</i></Link>
                            </div>
                        </div> : null
                }

                <div className="row">
                    <div className="col s12 center-align">
                        <h1>{this.state.title}</h1>
                    </div>
                </div>
                <section id="postContent" className="row">
                    <div className="col s12">
                        <div dangerouslySetInnerHTML={{ __html: this.state.data }}></div>
                    </div>
                    <div className="row">
                        <div className="col s6 left-align">
                            <p className="subText blue-text">Por: {this.state.by} desde {dateToString(this.state.created)} </p>
                        </div>
                        <div className="col s6 right-align">
                            {
                                this.state.updated ?
                                    <p className="subText blue-text">Actualizado el {dateToString(this.state.updated)}</p>
                                    : null
                            }
                        </div>
                    </div>
                </section>



                {
                    this.state.urlShare ?
                        <div id="share" className="row">
                            <div className="col s12 left-align shareButton">
                                <label className="shareText">Comparte este post:</label>
                                <div className="row">
                                    <div className="col s3 center-align">
                                        <i className="fab fa-facebook-square blue-text"
                                            onClick={this.shareFacebook.bind(this)}
                                        ></i>

                                    </div>
                                    <div className="col s3 center-align">
                                        <TwitterShareButton
                                            url={this.state.urlShare}
                                            title={`Innato Developers Blog | ${this.state.title} `}
                                            hashtags={["InnatoDevelopers"]}
                                            children={<i className="fab fa-twitter-square blue-text"></i>}
                                        />
                                    </div>
                                    <div className="col s3 center-align">
                                        <WhatsappShareButton
                                            url={this.state.urlShare}
                                            title={`Innato Developers Blog | ${this.state.title} `}
                                            separator={`${this.state.urlShare}`}
                                            children={<i className="fab fa-whatsapp-square green-text"></i>}
                                        />
                                    </div>
                                    <div className="col s3 center-align">
                                        <EmailShareButton
                                            url={this.state.urlShare}
                                            children={<i className="far fa-envelope gray-text"></i>}
                                            subject={`Innato Developers Blog | ${this.state.title} `}
                                            body={`${this.state.description}`}
                                        />
                                    </div>
                                </div>




                            </div>
                        </div>
                        : null
                }

            </div>
        )


    }




}


export default PostShow;