import React, { Component } from 'react';
import firebase from 'firebase';
import {
    getActiveBlog, getCategories, getCategorieData,
    eliminarDiacriticos, dateToString, getActiveBlogPagination,getActiveBlogLastFive
} from './blog.code';
import { Link } from 'react-router-dom';
import './blog.css'
import Loading from '../generics/loading.cmpt';

const $ = window.$;
class Posts extends Component {

    state = {
        posts: [],
        categories: [],
        firestore: firebase.firestore(),
        loading:true
    }


    componentWillMount() {
        getActiveBlogLastFive(this.state.firestore).then((result) => {
            this.setState({ posts: result.post },()=>{
                getCategories(this.state.firestore).then((result) => {
                    this.setState({ categories: result },()=>{
                        this.setState({loading:false})
                    })
                })
            });
        }).catch((error)=>{
            this.setState({loading:false});
        })
      

        $(document).ready(() => {
            document.title = `Innato Developers Blog | ${this.state.title} `;
            $("#inicio").hide();
            $("#header").addClass("BlogHeader")
        });
    }

    nextPagination() {
        this.setState({loading:true})
        let lastElement = this.state.posts[this.state.posts.length - 1];
        getActiveBlogPagination(this.state.firestore, lastElement).then((result) => {
            if(result.post.length > 0){
                this.setState({ posts: result.post },()=>{
                    this.setState({loading:false})
                });
            }else{
                this.setState({loading:false})
            }
        });
    }


    render() {

        return (
            <div className="blogContent container">
                <div className="row">
                    <div className="col s12 center-align white-text">
                        <h1>Innato Developers | Blog</h1>
                    </div>
                </div>
                {
                    this.state.loading ? 
                    <Loading /> : 
                
                <section className="row">
                    <div className="col s12">
                        <ul className="ulPostList">
                            {
                                this.state.posts.length > 0 ?
                                    this.state.posts.map((snap, index) => {
                                        let value = snap.data();
                                        return (

                                            <li key={index} className="postItem">
                                                <Link
                                                    to={{pathname:`/blog/${getCategorieData(this.state.categories, value.categorie).vlink}/${eliminarDiacriticos(value.title).trim().toLowerCase()}`,
                                                state:{id:value.id}}}
                                                >
                                                    <h5 className="titlePost">{value.title}</h5>
                                                    <p className="subText blue-text">Por: {value.by}</p>
                                                    <p className="subText blue-text">{dateToString(value.created)} </p>
                                                    <div className="divider" />
                                                </Link>
                                            </li>


                                        )
                                    })
                                    : null
                            }
                            <li className="right-align">
                                <button className="btn-flat waves-effect" type="button"
                                    onClick={this.nextPagination.bind(this)}
                                >Posts Anteriores</button>
                            </li>
                        </ul>
                    </div>
                </section>
                }

            </div>

        )
    }

}


export default Posts;
