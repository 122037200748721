import React, { Component } from 'react';
import { getBlog,activeBlog,getCategories,getCategorieData,eliminarDiacriticos } from './blog.code';
import { Link } from 'react-router-dom';
import ReactTable from "react-table";
import firebase from 'firebase';
import "react-table/react-table.css";

const M = window.M;
class PostsList extends Component {

    state = {
        posts: [],
        categories:[],
        firestore: firebase.firestore()
    }


    componentWillMount() {
        getBlog(this.state.firestore).then((result) => {
            this.setState({ posts: result });
        });

        getCategories(this.state.firestore).then((result)=>{
            this.setState({categories:result})
        })
    }

    componentDidMount() {
        var elems = document.querySelectorAll('.fixed-action-btn');
        M.FloatingActionButton.init(elems, {});
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 center-align">
                        {
                            this.state.posts.length > 0 ?
                                <ReactTable className="z-depth-4"
                                    data={this.state.posts}
                                    pageSize={6}
                                    columns={[
                                        {
                                            Header: "Posts",
                                            columns: [
                                                {
                                                    Header: "Título",
                                                    accessor: "title"
                                                },
                                                {
                                                    Header: "Activo",
                                                    accessor: "id",
                                                    Cell: row => (
                                                        <div className="center-align">
                                                            <label>
                                                                <input type="checkbox" checked={row.original.active} onChange={()=>{
                                                                    let data= {
                                                                        id:row.value,
                                                                        active:!row.original.active
                                                                    }
                                                                    activeBlog(this.state.firestore,data).then((result)=>{
                                                                        let index = row.index;
                                                                        let posts = this.state.posts;
                                                                        posts[index].active = data.active;
                                                                        this.setState({posts});
                                                                    })
                                                                }} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "Editar",
                                                    accessor: "id",
                                                    Cell: row => (
                                                        <div className="center-align">
                                                            <Link to={`/dashboard/post/${row.value}`} className="waves-effect waves btn-flat"
                                                            >
                                                                <i className="material-icons blue-text">edit</i>
                                                            </Link>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "Mostrar",
                                                    accessor: "id",
                                                    Cell: row => (
                                                        <div className="center-align">
                                                            <Link to={{
                                                                pathname:`/dashboard/preview/${getCategorieData(this.state.categories,row.original.categorie).vlink}/${eliminarDiacriticos(row.original.title).trim().toLowerCase()}/true`,
                                                                state: {id:row.value}
                                                            }} className="waves-effect waves btn-flat"
                                                            >
                                                                <i className="material-icons blue-text">remove_red_eye</i>
                                                            </Link>
                                                        </div>
                                                    )
                                                }
                                            ]
                                        }
                                    ]}
                                />

                                : <h5>Aún no cuentas con Posts agregados. <Link to="/dashboard/post">Agrega aquí</Link></h5>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="fixed-action-btn">
                            <Link to="/dashboard/post" className="btn-floating btn-large red">
                                <i className="large material-icons">add</i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default PostsList;