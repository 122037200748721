import React, { Component } from 'react';
import Header from './Header.cmpt';
import RouterApp from '../routing/routing.cmpt';
import './landing.css';
let logoMin =
  "https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Flogo.png?alt=media&token=734380bb-a6c1-488b-a411-739144239b15";
let logo =
  "https://firebasestorage.googleapis.com/v0/b/innatodevelopers-57b3a.appspot.com/o/landingInnato%2Flogofull.png?alt=media&token=13be08e8-91bb-47a4-9955-b00668b358a6";
const $ = window.$;

class Landing extends Component {

  componentDidMount() {
    var scroll_pos = 0;
    $(document).scroll(function () {
      scroll_pos = $(this).scrollTop();
      if (scroll_pos > 200) {
        $("#navMenu").removeClass("transparent");
        $("#navMenu").addClass("blueNav");
        $("#imgLogo").attr("src", logoMin);
        $("#imgLogo").addClass("img-min");
      } else {
        $("#navMenu").removeClass("blueNav");
        $("#navMenu").addClass("transparent");
        $("#imgLogo").removeClass("img-min");
        $("#imgLogo").attr("src", logo);
      }
    });

    $(document).ready(() => {
      $(".sidenav").sidenav();
      $("meta[property='og\\:title']").attr("content", "Innato Developers | Desarrollo de Software");
    });

  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <RouterApp />
      </React.Fragment>
    )
  }
}

export default Landing;