import React,{Component} from 'react';
import { Route, Switch} from 'react-router-dom';
import PostShow from '../blog/post-show.cmpt';
import PostsList from '../blog/post-list.cmpt';
import PostForm from '../blog/post-form.cmpt';
import Categories from '../blog/categories.cmpt';


class AdminRouter extends Component{

    render(){
        return(
            <Switch>
                <Route path="/dashboard/preview/:categorie/:title/:preview?" component={PostShow} />
                <Route path="/dashboard/post/:id?" component={PostForm} />
                <Route path="/dashboard/categories" component={Categories} />
               <Route path="/dashboard" component={PostsList} />
            </Switch>
        )
    }

}

export default AdminRouter;